import styled, { css } from "styled-components";
import { RoundedButton } from "./roundedButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faHouse } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { MobileMenu } from "./mobileMenu";
import { useState } from "react";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { useDescriptiveBlockQuery } from "../generated/graphql";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const LogoDesc = styled.div``;

const UpContainer = styled.div`
  display: flex;
  padding: 30px;

  gap: 2em;
  @media screen and (max-width: 800px) {
    padding: 10px;
    font-size: 0.8em;
    line-height: 1.2em;
    ${LogoDesc} {
      max-width: 100px;
    }
  }
`;

const MenuDesktopContainer = styled.div`
  display: flex;
  padding: 20px 20px;
  justify-content: space-around;
  background-color: #f4f3f3;

  @media screen and (max-width: 800px) {
    justify-content: space-between;
  }
`;
const Icon = styled(FontAwesomeIcon)``;

const MenuButton = styled.button<{ active?: boolean }>`
  text-align: center;
  text-transform: uppercase;
  border: none;
  background-color: transparent;
  font-size: 22;
  cursor: pointer;
  &.hide-mobile {
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  &.hide-desktop {
    @media screen and (min-width: 800px) {
      display: none;
    }
  }
  &.active {
    font-weight: bold;
    & ${Icon} {
      color: #be1522;
    }
  }
  ${(props) =>
    props.active &&
    css`
      font-weight: bold;
      & ${Icon} {
        color: #be1522;
      }
    `}
  &:hover {
    & ${Icon} {
      color: #be1522;
    }
  }
`;

const LogoImg = styled.div`
  background-image: url("/img/logo.webp");
  background-size: contain;
  height: 50px;
  width: 100%;
  background-repeat: no-repeat;
  margin-bottom: 10px;
`;
const LogoCt = styled.div`
  flex: 1;
  span {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }
  img {
    height: 20px;
  }
`;
function Logo() {
  return (
    <LogoCt>
      <LogoImg />
      <a
        href="https://www.instagram.com/matteonoleggioauto/"
        target="_blank"
        rel="noreferrer"
      >
        <span>
          <img src="https://www.cdnlogo.com/logos/i/92/instagram.svg" />
          @matteonoleggioauto
        </span>
      </a>
    </LogoCt>
  );
}

const Link = styled.a`
  &.hide-mobile {
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  &.hide-desktop {
    @media screen and (min-width: 800px) {
      display: none;
    }
  }
`;
function RoutedButton(props: {
  children: JSX.Element | string;
  route: string;
  className?: string;
}) {
  const location = useLocation();
  const currentPath = location.pathname;
  const active = currentPath === props.route;
  return (
    <Link href={props.route} className={props.className}>
      <MenuButton active={active} className={props.className}>
        {props.children}
      </MenuButton>
    </Link>
  );
}

function HeaderText() {
  const [{ data }] = useDescriptiveBlockQuery({
    variables: { id: "7yhTuLsJbvEezMpIL5LCMF" },
  });

  return (
    <>{documentToReactComponents(data?.descriptiveBlock?.description?.json)}</>
  );
}

export function Header() {
  const [menuActive, setMenuActive] = useState(false);
  return (
    <header>
      <MobileMenu active={menuActive} onClose={() => setMenuActive(false)} />
      <UpContainer>
        <Logo />
        <LogoDesc>
          <HeaderText />
        </LogoDesc>
      </UpContainer>
      <MenuDesktopContainer>
        <RoutedButton route="/">
          <Icon icon={faHouse} size="2x" />
        </RoutedButton>

        <RoutedButton className="hide-mobile" route="/noleggio">
          Noleggio a lungo termine
        </RoutedButton>

        <RoutedButton className="hide-mobile" route="/chisiamo">
          Chi siamo
        </RoutedButton>

        <RoutedButton className="hide-mobile" route="/offerte">
          Auto
        </RoutedButton>
        <RoutedButton className="hide-mobile" route="/furgoni">
          Furgoni
        </RoutedButton>
        <RoutedButton className="hide-mobile" route="/fisco">
          Fiscalità
        </RoutedButton>
        <RoutedButton className="hide-mobile" route="/contatti">
          Contatti
        </RoutedButton>

        <Link href="/preventivo">
          <RoundedButton className="hide-mobile solid">
            Richiedi un preventivo
          </RoundedButton>
        </Link>
        <MenuButton
          className="hide-desktop"
          onClick={() => setMenuActive(true)}
        >
          <FontAwesomeIcon icon={faBars} size="2x" />
        </MenuButton>
      </MenuDesktopContainer>
    </header>
  );
}
